import React, {Component} from 'react';
import PropTypes from "prop-types";
import './Layouts.css';
import {getDebt} from "../api/Debt";
import {t} from "i18next";

class ActionCompleted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toPayment: false,
            debt: null,
        };
        this.goToTPV = this.goToTPV.bind(this);
    }

    async componentDidMount() {
        try {
            if (this.props.debtId !== '') {
                const debtPath = !isNaN(this.props.debtId) ? '/deuda/' + this.props.debtId : this.props.debtId;
                const debt = await getDebt(debtPath);
                this.setState({ debt, toPayment: debt && !debt.solved });
            }
        } catch (error) {
            console.log(error);
        }
    }

    goToTPV() {
        if (null === localStorage.getItem('openTpv')) {
            window.location.replace(this.state.debt.link);
        } else {
            localStorage.removeItem('openTpv');
            window.open(this.state.debt.link, "_blank");
        }
    }

    render() {
        return (
            <div className="container_body gracias">
                <div className="gracias_s1">
                    <h1 className="title title_completed">{t("Ya has finalizado")}<br/>{t("esta gestión")}<br/><br/>
                        <div hidden={!this.state.toPayment}>{t("¡Gracias!")}</div>
                    </h1>
                    <div className="center">
                        <img src="/figures/complete.svg" alt="" className="figure_complete"/>
                    </div>
                </div>
                <div className="pay_container">
                    <h3 className="subtitle" hidden={!this.state.toPayment}>
                        {t("Tambien puedes pagar ahora en el siguiente botón")}
                        <button className='btn my-4 btn-pay' onClick={this.goToTPV}>
                            {t('Pagar Ahora')}
                        </button>
                    </h3>
                </div>
                <div className="center">
                    <h1 className="title thanks_for_using">
                        {t("Gracias por usar")}
                        <img src="/logo512.png" alt="" className="logo_owe"/>
                    </h1>
                </div>
            </div>

        );
    }
}

ActionCompleted.propTypes = {
    debtId: PropTypes.string.isRequired,
};

export default ActionCompleted;