import React, {Component} from 'react';
import './Layouts.css';

class WaitingPaymentFinish extends Component {
    render() {
        return (
            <div className="container_body gracias">
                <div className="waiting_s1 center">
                    <h1 className="title waiting_title">Estamos esperando a que finalice su pago</h1>
                </div>
                <div className="center">
                    <h1 className="title thanks_for_using">
                        Gracias por usar
                        <img src="logo512.png" alt="" className="logo_owe"/>
                    </h1>
                </div>
            </div>

        );
    }
}

export default WaitingPaymentFinish;